<template>
  <div>
    <van-field :label="uploadLabel" :required="required" :name="parameter" type="file" :rules="[{
      required: true, message: message
    }]">
      <template #input>
        <van-uploader v-model="value" name="files" :readonly="readonly" :max-count="maxCount" :accept="accept"
          :after-read="afterRead" :before-read="beforeRead" />
        <div class="img" v-if="demonstrate">
          <van-image width="80" height="80" fit="cover" :src="demonstrate" />
          <div class="test" @click="look(demonstrate)">示例图 <van-icon name="search" /></div>
        </div>
      </template>
    </van-field>


  </div>
</template>

<script>
import Compressor from 'compressorjs';
import axios from "axios";
import { url } from "../utils/request.js";

import {
  Field,
  Uploader,
  Image as VanImage,
  Toast, ImagePreview,
  Lazyload
} from "vant";
export default {
  data() {
    return {
      value: [],
      message: '222'
    };
  },
  created() {
    this.message = `请上传${this.uploadLabel}`
  },
  components: {
    [Field.name]: Field, // 排版
    [Uploader.name]: Uploader, // 上传
    [VanImage.name]: VanImage, // 图片
    [Toast.name]: Toast, // 文字提示
    [Lazyload.name]: Lazyload, // 文字提示
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  watch: {
    defaultValue(newValue, oldValue) {
      this.value = newValue;
    },
  },
  props: {
    defaultValue: {
      type: Array,
      default: function () {
        return []
      }
    }, // 绑定上传组件的值
    uploadLabel: {
      type: String,
      default: '文件上传'
    }, // 上传按钮的文字
    parameter: {
      type: String,
      default: 'phone'
    }, // 绑定的参数名
    accept: {
      type: String,
      default: 'image/*'
    }, // 接受的文件类型
    readonly: {
      type: Boolean,
      default: false
    }, // 是否只读
    required: {
      type: Boolean,
      default: true
    }, // 必填小红心
    maxCount: {
      type: Number,
      default: 1
    }, // 默认数量
    demonstrate: {
      type: String,
      default: ''
    }, // 默认数量
    param: {
      type: Object,
      default: function () {
        return {}
      }
    }, // 额外参数
  },
  methods: {
    afterRead(file) {
      this.uploadFile(file);
    },
    async uploadFile(file) {
      let _this = this;
      const formData = new FormData();
      formData.append('files', file.file);
      formData.append('param', JSON.stringify(this.param));
      try {
        const toast = Toast.loading({
          message: `文件${this.param.verify ? '识别' :'上传'}中`,
          forbidClick: true,
          position: "top",
          duration: 0,
        })
        const response = await axios.post(url[3][0], formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response.status == 200 && response.data.code == 1) {
          if(response.data.data.url){
            _this.value = [{ url: response.data.data.url }];
          }else{
            _this.value = [{ url: response.data.data }];
          }
          _this.$emit('change', { url: response.data.data, name: _this.parameter });
          toast.clear();
        } else {
          toast.clear();
          Toast(response.data.msg)
        }
      } catch (error) {
        // 处理上传失败的错误
        console.error('上传失败', error);
      }
    },

    beforeRead(file) {
      return new Promise((resolve) => {
        console.log(file.size);
        let quality = 1
        if (file.size < 1000 * 1000) { //小于1M
          quality = 0.8
        } else if (file.size < 5000 * 1000) { //小于5M
          quality = 0.6
        } else if (file.size < 10000 * 1000) { //小于10M
          quality = 0.4
        } else { //大于10M
          quality = 0.2
        }

        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: quality,   //压缩比例0-1
          success: resolve,
          error(err) {
            console.log(err);
          },
        });
      });
    },

    // 图片预览
    look(e) {
      ImagePreview({
        images: [e],
        closeable: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  position: relative;

  img {
    display: block;
  }

  .test {
    position: absolute;
    left: 0;
    bottom: 14px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: #fff;
    font-size: 22px;
  }
}
</style>
