<template>
  <div class="course">
    <li class="title">获取电子证书</li>
    <van-cell-group inset style="padding-top: 10px">
      <van-form @submit="onSubmit" inset>
        <van-field v-model="name" name="card" label="身份证号" :maxlength="18" placeholder="上传身份证正面后识别" required readonly
          :rules="[{ required: true, validator, message: '请填写正确的身份证号' }]" />
        <van-field v-model="phone" name="unit" label="邀请单位" placeholder="邀请单位，没有可不填写" />

        <van-field readonly clickable label="申报项目" name="entry" :value="entry" placeholder="请选择申报项目"
          @click="showPicker = true" required :rules="[{ required: true, message: '请选择申报项目' }]" />

        <upload-field @change="handleUploadChange" parameter="front" :demonstrate="tip['front']" :param="param"
          uploadLabel="身份证正面" />
        <upload-field @change="handleUploadChange" parameter="opposite" :demonstrate="tip['opposite']"
          uploadLabel="身份证反面" />
        <upload-field @change="handleUploadChange" parameter="head" :demonstrate="tip['head']" uploadLabel="头像" />

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>

        <div class="tips">
          <div class="t"><span>*</span>温馨提示</div>
          <li>1、申请人必须<span>[年满18周岁]</span></li>
          <li>2、头像是生成证书必要条件，请上传本人一寸证件照</li>
          <li>3、可点击查看<span @click="look('certificate')">[选手证书最终样式]</span></li>
        </div>
      </van-form>
    </van-cell-group>


    <div class="copy"><span v-if="tip.name">{{ tip.name }}</span> 版权所有</div>


    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import UploadField from '../components/UploadField.vue';
import { Dialog, ImagePreview, Toast } from 'vant';
export default {
  data() {
    return {
      name: "",
      phone: "",
      front: [],  // 正面
      opposite: [],   // 反面
      head: [], // 头像

      show: false,

      tip: {},

      showPicker: false,
      entry: '',
      showPicker: false,
      columns: [],

      // 启动身份识别参数
      param: { verify: true },
      IdNum: '',
    };
  },
  components: {
    'upload-field': UploadField,  // 封装上传组件
    [Dialog.Component.name]: Dialog.Component,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Toast.name]: Toast, // 文字提示
  },
  created() {
    this.post({});

  },
  mounted() { },
  computed: {},
  methods: {
    // 查看示例图
    look(e) {
      ImagePreview({
        images: [this.tip[e]],
        closeable: true,
      });
    },
    // 选择项目
    onConfirm(value) {
      this.entry = value;
      this.showPicker = false;
    },

    // 提交
    onSubmit(values) {

      if (!this.isAdult(values.card)) {
        Toast('您暂未年满18周岁！');
        return false;
      }

      values.front = values.front[0].url;
      values.opposite = values.opposite[0].url;
      values.head = values.head[0].url;
      values.entry = values.entry.toString();
      values.uid = this.$localStorage.get('userInfo')['id'];
      values.role = 1;

      this.$request(5, values)
        .then((res) => {
          Toast(res);
          setTimeout(() => {
            this.$router.push({ path: '/examine' });
          }, 1000)
        })
        .catch((message) => {
          Toast(message);
          // Toast(message);
        })
    },

    // 是否成年
    isAdult(id) {
      let birthdate = new Date(`${id.substring(6, 10)}-${id.substring(10, 12)}-${id.substring(12, 14)}`);
      let currentdate = new Date();
      let a = birthdate.getTime();
      let b = currentdate.getTime();
      let days = Math.floor((b - a) / (24 * 3600 * 1000));
      return days >= 6570 ? true : false;
    },

    // 图片上传回调
    handleUploadChange(file) {
      if(file.url.IdNum){
        this[file.name] = [{ url: file.url.url }];
        this.name = file.url.IdNum.IdNum || '';
        this.IdNum = JSON.stringify(file.url.IdNum);
      }else{
        this[file.name] = [{ url: file.url }];
      }
    },

    // 身份证正则
    validator(val) {
      let reg = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/;
      return reg.test(val);
    },

    // 加载配置
    post(e) {
      this.$request(1, e)
        .then((res) => {
          this.tip = JSON.parse(res.tip);
          console.log(this.tip);
          let arr = [];
          res.project.forEach((i) => {
            arr.push(i.n_title)
          })
          this.columns = arr;
        })
        .catch((message) => {
          console.log(message);
          // Toast(message);
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.mb20 {
  margin-bottom: 20px;
}

.ma {
  border-radius: 10px;
  padding: 30px;
  width: 80%;

  .ma1 {
    padding-top: 30px;
    text-align: center;

    li {
      font-size: 26px;
      line-height: 50px;
      color: #888;

      span {
        color: red;
      }
    }
  }
}

.course {
  min-height: 100vh;
  background-color: #f7f8fa;

  .title {
    margin: 0;
    padding: 40px 32px 32px;
    color: rgba(69, 90, 100, 0.8);
    font-weight: normal;
    font-size: 28px;
    line-height: 32px;
  }

  .tips {
    margin-bottom: 30px;

    .t {
      padding: 30px 32px;
      color: rgba(69, 90, 100, 0.8);
      font-weight: normal;
      font-size: 28px;
      line-height: 32px;
    }

    li {
      text-indent: 1.5em;
      font-size: 26px;
      color: rgba(69, 90, 100, 0.6);
      line-height: 50px;
    }

    span {
      text-indent: 0;
      display: inline-block;
      margin-right: 5px;
      color: red;
    }
  }

  .copy {
    // position: fixed;
    // bottom: 30px;
    padding: 35px;
    text-align: center;
    width: 100%;
    left: 0;
    font-size: 24px;
    color: #999;
  }
}
</style>
